export const environment = {
    production: true,
    calculator: {
        pageTitleSuffix: 'HSBC',
        clientCode: 'hsbc',
        clientId: '5e61c0270e36631fe0f0b56d',
        apiKey: 'b3958692-3d7c-482a-a51e-d178466b1550',
        clientCalculator: 'targetplanner',
        soAUrl: 'soa'
    },
    calculators: [
        { pageTitleSuffix: 'HSBC', clientCalculator: 'targetplanner', clientCode: null, clientId: null, apiKey: null, soAUrl: null  },
        { pageTitleSuffix: 'HSBC', clientCalculator: 'spendingplanner', clientCode: null, clientId: null, apiKey: null, soAUrl: null  }
    ],
    appInsights: {
        instrumentationKey: '5f4acfd4-234e-4704-b169-dec0aa153e57',
        enableDebug: false
    },
    switches: {
        isErrorTrapActive: true,
        isTestingToolVisible: false,
        isUnitTesting: false,
        showPrintPreview: false,
        trackEvents: true,
        useDummyContent: true, //for testing static JSON content
        useDummyInit: false, //for using dummy init data
        useDummyResults: false, //for using dummy init data
        useRandomizedChartData: false,
        useStaticUrlInit: false,
        openPreviewWindow: true
    },
    urlsStatic: {
        content: '../assets/_data/content.json',
        apiResponse: '../assets/_data/api-response.json',
        init: '../assets/_data/init.json'
    },
    urls: {
        base: 'https://hcbtas-p-calc-hsbc-api.azurewebsites.net',
        init: '/app/{0}',
        calc: '/projections',
        content: '/content/{0}/{1}',
        lifeexpectancy: 'https://hcbtas-p-calc-hsbc-api.azurewebsites.net/lifeexpectancy',

        //PDF SOA URLS - local development target the local PdfController. dev/uat/prod environments target Azure Cloud Service.
        host: window.location.origin,
        pdfServiceGenerate: 'https://apps.willistowerswatson.com/pdf/GeneratePdfPost',
        pdfServiceGetPdfModel: 'https://apps.willistowerswatson.com/pdf/GetPDFModel',
        pdfServiceSetPdfModel: 'https://apps.willistowerswatson.com/pdf/SetPDFModel',
    }
};
